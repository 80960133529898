import { MultiWatchSources, useIntersectionObserver } from '@vueuse/core'
import { Ref } from 'vue'

export default function useInfiniteScroll<
  Response extends {
    count?: number
    next?: string | null
    previous?: string | null
    results?: Item[]
    page_size?: number
  },
  Item = NonNullable<Response['results']>[number],
  Skeleton = Item
>(
  key: string,
  bottom: Ref<HTMLElement | undefined>,
  fetchCallback: (page: number) => Promise<Response>,
  options?: {
    watch?: MultiWatchSources
    skeleton?: Skeleton
    skeletonNum?: number
  }
) {
  const pagination = usePagination()
  const page = useState(`${key}-page`, () => pagination.page.value)

  const fetchedPage = useState(`${key}-fetchedPage`, () => 0)
  const items = useState<Item[]>(`${key}-items`, () => [])
  const itemsCount = useState(`${key}-itemsCount`, () => items.value.length)
  const shouldFetchMore = useState(`${key}-shouldFetchMore`, () => false)

  const { execute } = useAsyncData(key, async () => {
    try {
      if (fetchedPage.value === page.value) return

      if (!fetchedPage.value && options?.skeleton) {
        const skeletonNum = options.skeletonNum ?? 6
        items.value.push(...new Array(skeletonNum).fill(options.skeleton))
      }

      const result = await fetchCallback(page.value)
      if (result.results) {
        if (!fetchedPage.value) items.value = result.results
        else items.value.push(...result.results)
      }
      itemsCount.value = result.count ?? itemsCount.value
      fetchedPage.value = page.value
      shouldFetchMore.value = !!result.next
    } catch {
      shouldFetchMore.value = false
    }
  })

  const reset = () => {
    items.value = []
    page.value = 1
    fetchedPage.value = 0
  }

  const fetchNext = async () => {
    if (!shouldFetchMore.value) return false
    page.value++
    await execute()
    return true
  }

  const fetchAll = async () => {
    if (await fetchNext()) await fetchAll()
  }

  const intersectionObserver = useIntersectionObserver(
    bottom,
    async ([{ isIntersecting }]) => {
      if (isIntersecting) await fetchNext()
    },
    { rootMargin: '400px' }
  )

  if (options?.watch) {
    watch(options.watch, async () => {
      intersectionObserver.pause()
      reset()
      await execute()
      await nextTick()
      intersectionObserver.resume()
    })
  }

  onUnmounted(reset)

  return { items, itemsCount, fetchAll }
}
