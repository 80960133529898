export default function usePagination() {
  const route = useRoute()

  const page = computed(() => {
    const pageQuery = Number(route.query.page)
    if (isNaN(pageQuery)) return 1
    return pageQuery
  })

  const getPageLink = (pageNumber: number) => ({
    query: {
      ...route.query,
      page: pageNumber
    }
  })

  const nextPageLink = computed(() => getPageLink(page.value + 1))
  const previousPageLink = computed(() => getPageLink(page.value - 1))

  return {
    page,
    getPageLink,
    nextPageLink,
    previousPageLink
  }
}

export type PaginationState = ReturnType<typeof usePagination>
